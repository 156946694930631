import React, { useEffect, useState } from 'react';
import jsSHA from "jssha";
import pageConfig from '../page.config';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { flyingAnimation } from '../animation.config';
import Countdown from './CountDown';
import { createPageHit } from '../firebase/Helpers';


function calculateSHA256Hash(input: string) {
  const shaObj = new jsSHA("SHA-256", "TEXT");
  shaObj.update(input);
  return shaObj.getHash("HEX");
}

interface LoginComponentProps {

}

const LoginComponent = (props: LoginComponentProps) => {
    const [password, setPassword] = useState('');
    const [isCorrectPassword, setIsCorrectPassword] = useState(false);
    const navigate = useNavigate();
    const [dots, setDots] = useState(0);

    setTimeout(() => {
        if (dots === 3) {
        setDots(0);
        } else {
        setDots(dots + 1);
        }
    }, 500);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const hashedPassword = calculateSHA256Hash(event.target.value);
        setPassword(event.target.value);

        // get the mapped component from the page config if it exists
        const mappedComponent = pageConfig.find((page) => page.password === hashedPassword);
        if (mappedComponent) {
            setIsCorrectPassword(true);
            createPageHit(mappedComponent.name).then(() => {
                navigate(mappedComponent.path);
            })
        }
    };

    useEffect(() => {
        createPageHit('Home');
    }, []);

    const inputClass = `shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${isCorrectPassword ? 'border-green-500 animate-pulse' : ''}`;

    return (
        <motion.div 
            initial={flyingAnimation.initial}
            animate={flyingAnimation.animate}
            exit={flyingAnimation.exit}
            transition={flyingAnimation.transition}
            className='flex flex-col justify-center items-center h-full w-full'
        >

            <p className='self-start text-lg font-bold mb-5'>
                {dots === 0 && 'enter password'}
                {dots === 1 && 'enter password.'}
                {dots === 2 && 'enter password..'}
                {dots === 3 && 'enter password...'}
            </p>

            <input
                type="password"
                value={password}
                onChange={handleChange}
                className={inputClass}
            />

            <span className='h-10'/>

            <Countdown targetDate='2024-05-31T16:25:00Z' />

            <span className='h-10'/>

            <button
                onClick={() => navigate('846d124b6785689c70bc65cf99e0ea2e896349ffeb38cf1b1224b88b3982fe94')}
                className='bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-1/2'
            >
                🌼 Flowers
            </button>
        </motion.div>
        
    );
};

export default LoginComponent;
