import { collection, getDocs, addDoc, query, orderBy, where, limit } from 'firebase/firestore';
import db from '../firestore';
import { PageHitModel } from './PageHitModel';
import axios from 'axios';
import { FlowerColorModel } from './FlowerColorModel';
import { HighScoreModel } from './HighscoreModel';

export const getIP = async (): Promise<string> => {
  const response = await axios.get('https://api.ipify.org?format=json');
  return response.data.ip;
};

export const getHighScores = async (first: number): Promise<HighScoreModel[]> => {
  const collectionRef = collection(db, 'highscores');
  const q = query(collectionRef, orderBy('score', 'desc'), orderBy('date', 'desc'), orderBy('ip'), limit(first));
  const querySnapshot = await getDocs(q);
  const docs = querySnapshot.docs;
  return docs.map(doc => doc.data() as HighScoreModel);
};

export const saveHighScore = async (highScore: HighScoreModel): Promise<void> => {
  try {
    const collectionRef = collection(db, 'highscores');
    await addDoc(collectionRef, highScore);
  } catch (error) {
    throw new Error('Failed to save high score: ' + error);
  }
}

export const createPageHit = async (page: string): Promise<void> => {
  const pageHit: PageHitModel = {
    page: page,
    timestamp: new Date(),
    ip: await getIP(),
  };

  try {
    const collectionRef = collection(db, 'page-hits');
    await addDoc(collectionRef, pageHit);
  } catch (error) {
    throw new Error('Failed to create page hit: ' + error);
  }
}

export const createFlowerColor = async (data: FlowerColorModel): Promise<void> => {
  try {
    const collectionRef = collection(db, 'flower-color');
    await addDoc(collectionRef, data);
  } catch (error) {
    throw new Error('Failed to create flower color: ' + error);
  }
}

export const getFlowerColor = async (): Promise<FlowerColorModel> => {
  const collectionRef = collection(db, 'flower-color');

  // Get today's date at the start of the day
  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);

  // Get today's date at the end of the day
  const endOfDay = new Date();
  endOfDay.setHours(23, 59, 59, 999);

  // Query for documents where 'date' is between startOfDay and endOfDay
  const q = query(collectionRef, where('date', '>=', startOfDay), where('date', '<=', endOfDay), orderBy('date', 'desc'));
  
  const querySnapshot = await getDocs(q);
  const docs = querySnapshot.docs;
  if (docs.length === 0) {
    throw new Error('No flower color found');
  }
  const docData = docs[0].data();
  return {
    color: docData.color,
    date: docData.date.toDate(),
  };
}
