import { Stage, Container, Sprite, Text, useApp, useTick } from '@pixi/react';


interface TimerProps {
    timer: number
    stageSize: { width: number, height: number }
    onTimerEnd: () => void
}

const Timer = (props: TimerProps) => {

    useTick(() => {
        if(props.timer <= 0) {
            props.onTimerEnd();
        }
    });


    return (
        <Text text={`Time: ${props.timer}`}/>
    )
}

export default Timer;