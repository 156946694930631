import { Container, Sprite, useTick } from "@pixi/react";
import Powerup, { PowerupConfig } from "../sprites/Powerup";
import { ElementRef, useContext, useState } from "react";
import starbucksCup from "../../../assets/starbucks.png";
import { PowerupModel, Powerups, PowerupType, InitiatedPowerupModel } from "../Powerup.model";
import { PowerupContext } from "../providers/PowerupContext";
import { getRandomRectangle, pickRandom } from "../helpers";


interface PowerupProviderProps {
    squirrelRef: React.RefObject<ElementRef<typeof Sprite>>
    stageSize: { width: number, height: number }
    addPowerup: (powerup: InitiatedPowerupModel) => void
}

const PowerupProvider = (props: PowerupProviderProps) => {
    const [allowSpawn, setAllowSpawn] = useState(true);
    const [config, setConfig] = useState<PowerupConfig | null>(null);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [spawnTimer, setSpawnTimer] = useState<NodeJS.Timeout | null>(null);

    const SPAWN_DURATION = 5000;
    
    const handlePowerup = (powerup: PowerupConfig) => {
        setConfig(powerup);
        setTimer(
            setTimeout(() => {
                setConfig(null);
            }, SPAWN_DURATION)
        )

        disableSpawnForDuration(Math.random() * SPAWN_DURATION);
    };

    const disableSpawnForDuration = (duration: number) => {
        if (spawnTimer) {
            clearTimeout(spawnTimer);
        }

        setAllowSpawn(false);
        
        setSpawnTimer(
            setTimeout(() => {
                setAllowSpawn(true);
                setSpawnTimer(null);
            }, duration)
        );
    };

    useTick(() => {

        if(!allowSpawn) {
            return;
        }

        const powerupValues = Object.values(Powerups);

        // array of the spawnRates of each powerup at the correct index
        const spawnRates = powerupValues.map((powerup: PowerupModel) => powerup.spawnRate);
        const index = pickRandom(spawnRates);

        if (!config && index !== null) {

            const randomPowerUp = powerupValues[index];

            let rect = getRandomRectangle(props.stageSize.width, props.stageSize.height, 150, 150);

            while (props.squirrelRef.current && props.squirrelRef.current.getBounds().intersects(rect)) {
                rect = getRandomRectangle(props.stageSize.width, props.stageSize.height, 150, 150);
            }
            
            const powerupConfig: PowerupConfig = {
                powerup: {
                    ...randomPowerUp,
                    id: Math.round(Math.random() * 100000),
                },
                position: {
                    x: rect.x + rect.width / 2,
                    y: rect.y + rect.height / 2
                },
                rotation: Math.random() * 360
            };

            handlePowerup(powerupConfig);
        }
    });
    
    return (
        <Container>
        {config && (
            <Powerup
            config={config}
            squirrelRef={props.squirrelRef}
            onCollide={() => {
                setConfig(prev => {
                    if (!prev) {
                        return null;
                    }
                    console.log('GamePowerupProvider, Adding powerup', prev.powerup, props.addPowerup);
                    props.addPowerup({...prev.powerup});
                    disableSpawnForDuration(Math.random() * SPAWN_DURATION);
                    clearTimeout(timer as NodeJS.Timeout);
                    return null;
                });
            }}
            />
        )}
        </Container>
    )
}

export default PowerupProvider;