import React, { useState } from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import { flyingAnimation } from '../../animation.config';
import Wordle from '../WordleGame';

const AnimatedBlock: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
      <motion.div
        initial={flyingAnimation.initial}
        animate={flyingAnimation.animate}
        exit={flyingAnimation.exit}
        transition={flyingAnimation.transition}
        className='flex flex-col justify-center items-center gap-y-5'
      >
        {children}
      </motion.div>
    );
};

const ValentinesDay = () => {
    const [displayWordle, setDisplayWordle] = useState(true);
    const [wordleKey, setWordleKey] = useState(0); // Add this line

    const onWordleWin = () => {
        setDisplayWordle(false);
    }

    const onWordleLose = () => {
        setWordleKey(prevKey => prevKey + 1); // Update the key
    }

    const showComponent = () => {
        if (displayWordle) {
            return (
                <AnimatedBlock key={"wordle"}>
                    <Wordle 
                        key={wordleKey} // Add the key prop
                        title="L'Wordle"
                        word='ducks'
                        onWin={onWordleWin}
                        onLose={onWordleLose}
                    />
                </AnimatedBlock>
            )
        }

        return (
            <AnimatedBlock key={"poem"}>
                <p className='text-2xl'>
                    ducks.<br />
                    where would we be without them? <small style={{fontSize: '0.8rem'}}>lol</small><br />
                    <br />
                    i knew on our first date that you were <i>the one.</i><br />
                    maybe thats why i was so nervous.<br />
                    <br />
                    everyday is an adventure with you, something exciting and new.<br />
                    just like the day we saw the ducks.<br />
                    i can't wait for all the adventures i get to share with <b>you.</b>
                </p>
                <img src='img/ducks.png' alt='us' style={{width: '30vw'}} />
            </AnimatedBlock>
        )
    }

    return (
        <motion.div className='flex flex-col justify-center items-center' 
                initial={flyingAnimation.initial}
                animate={flyingAnimation.animate}
                exit={flyingAnimation.exit}
                transition={flyingAnimation.transition}
            >
                <AnimatePresence>
                    {showComponent()}
                </AnimatePresence>
        </motion.div>
    )
}

export default ValentinesDay;