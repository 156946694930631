import React, { ElementRef, useContext, useEffect, useState } from 'react';
import { Stage, Container, Sprite, Text } from '@pixi/react';
import Squirrel from './sprites/Squirrel';
import Cashew from './sprites/Cashew';
import Timer from './components/Timer';
import GameOver from './components/GameOver';
import PowerupProvider from './components/PowerupProvider';
import { PowerupContext } from './providers/PowerupContext';
import { PowerupModel, InitiatedPowerupModel } from './Powerup.model';
import PowerupsDisplay from './components/PowerupsDisplay';
import { getRandomRectangle } from './helpers';

interface CashewItem {
    id: number
    position: { x: number, y: number }
    rotation: number
    isGolden: boolean
    ref: React.RefObject<ElementRef<typeof Sprite>>
}

export const SquirrelStage = () => {

    const NUM_CASHEWS = 3;

    const stageSize = { width: 800, height: 600 };
    const stageRef = React.createRef<ElementRef<typeof Stage>>();

    const [cashews, setCashews] = useState<CashewItem[]>([]);
 
    const squirrelRef = React.createRef<ElementRef<typeof Sprite>>();

    const [score, setScore] = useState(0);
    const [timer, setTimer] = useState(30);
    const [gameOver, setGameOver] = useState(false);

    const [powerups, setPowerups] = React.useState<InitiatedPowerupModel[]>([]);

    const addPowerup = (powerup: InitiatedPowerupModel) => {
        // Check if this is a power up that should be processed by other components
        if (powerup.type === 'time') {
            setTimer(timer + powerup.duration / 1000);
            return;
        }
        setPowerups([...powerups, powerup]);
    };

    const removePowerup = (powerup: InitiatedPowerupModel) => {
        setPowerups(powerups.filter(p => p.id !== powerup.id));
    };

    const createCashew = (id: number): CashewItem => {
        const cashewWidth = 100;
        const cashewHeight = 100;

        let rect = getRandomRectangle(stageSize.width, stageSize.height, cashewWidth, cashewHeight);

        while (squirrelRef.current && squirrelRef.current.getBounds().intersects(rect)) {
            rect = getRandomRectangle(stageSize.width, stageSize.height, cashewWidth, cashewHeight);
        }

        const cashewX = rect.x + rect.width / 2;
        const cashewY = rect.y + rect.height / 2;

        return {
            id,
            position: { x: cashewX, y: cashewY },
            rotation: Math.random() * 360,
            isGolden: Math.random() < 0.1,
            ref: React.createRef<ElementRef<typeof Sprite>>()
        };
    }

    const handleCashewCollide = (id: number) => {
        const cashew = cashews.find(cashew => cashew.id === id);
        if (!cashew) {
            return;
        }
        const goldenCashew = cashew.isGolden;

        setScore(score + (goldenCashew ? 5 : 1));

        const newCashew = createCashew(id);
        setCashews([...cashews.filter(cashew => cashew.id !== id), newCashew]);

        console.log('Collided', score);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(timer - 1);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [timer]);

    useEffect(() => {
        const newCashews = Array.from({ length: NUM_CASHEWS }, (_, i) => createCashew(i));
        setCashews(newCashews);
    }, []);

    const onTimerEnd = () => {
        setGameOver(true);
    }

    const handleRestart = () => {
        // Reload the page
        window.location.reload();
    }

    const renderStage = () => {
        return (
            <Stage 
                ref={stageRef}
                options={{ 
                    background: 0xffffff, 
                    width: stageSize.width, 
                    height: stageSize.height, 
                    hello: true,
                }}
                onMount={(app) => {
                    app.ticker.maxFPS = 60;
                    app.ticker.minFPS = 50;
                }}
            >
                
                <Container>
                    <Squirrel 
                        squirrelRef={squirrelRef}
                        score={score}
                        powerups={powerups}
                        removePowerup={removePowerup}
                    />
                </Container>

                <Container>
                    {cashews.map((cashew) => (
                        <Cashew
                            key={cashew.id}
                            cashewRef={cashew.ref}
                            squirrelRef={squirrelRef}
                            onCollide={() => handleCashewCollide(cashew.id)}
                            position={cashew.position}
                            rotation={cashew.rotation}
                            isGolden={cashew.isGolden}
                        />
                    ))}
                </Container>

                <Container>
                    <PowerupProvider
                        squirrelRef={squirrelRef}
                        addPowerup={addPowerup}
                        stageSize={stageSize}
                    />
                </Container>

                <Container position={{
                    x: stageSize.width - 150,
                    y: 10
                }}>
                    <Text text={`Score: ${score}`} y={30}/>
                    <Timer 
                        timer={timer} 
                        stageSize={stageSize}
                        onTimerEnd={onTimerEnd}
                    />
                </Container>

                <PowerupsDisplay powerups={powerups} />
            </Stage>
        )
    };

    return (
        <div>
            {!gameOver ? renderStage() : <GameOver score={score} onRestart={handleRestart} />} 
        </div>
    );
};