import React from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import pageConfig from '../page.config';
import LoginComponent from './LoginComponent';

import {AnimatePresence} from 'framer-motion';

function AnimatedRoutes() {
    const location = useLocation();

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<LoginComponent />} />
                {pageConfig.map((page) => {
                return (
                    <Route key={page.path} path={page.path} element={page.component()}  />
                )
                })}
            </Routes>
        </AnimatePresence>
    )

}

export default AnimatedRoutes;