import React, { useState } from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import { flyingAnimation } from '../../animation.config';

interface AnswerInputProps {
    correctAnswer: string;
    onCorrectAnswer: () => void;
}

interface ColorButtonProps {
    color: string;
    onClick: () => void;
}

interface NumberSliderProps {
    correctNumber: number;
    onCorrectAnswer: () => void;
}

const NumberSlider: React.FC<NumberSliderProps> = ({ correctNumber, onCorrectAnswer }) => {
    const [sliderValue, setSliderValue] = useState(0);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = Number(event.target.value);
        setSliderValue(newValue);
        if (newValue === correctNumber) {
            setTimeout(() => {
                onCorrectAnswer();
            }, 300);
        }
    };

    return (
        <input 
            type="range" 
            min="0" 
            max="100"
            value={sliderValue} 
            onChange={handleChange} 
            className="slider bg-blue-500 h-1 w-full rounded-full"
        />
    );
};

const ColorButton: React.FC<ColorButtonProps> = ({ color, onClick }) => {
    return (
        <div 
            onClick={onClick} 
            style={{ backgroundColor: color}}
            className={`h-10 w-10 rounded-full cursor-pointer`}
        />
    );
};

const AnswerInput: React.FC<AnswerInputProps> = ({ correctAnswer, onCorrectAnswer }) => {
    const [inputValue, setInputValue] = useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
        if (event.target.value === correctAnswer) {
            setTimeout(() => {
                onCorrectAnswer();
            }, 300);
        }
    };

    return (
        <input 
            type="text" 
            value={inputValue} 
            onChange={handleChange} 
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
    );
};



const AnimatedBlock: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
      <motion.div
        initial={flyingAnimation.initial}
        animate={flyingAnimation.animate}
        exit={flyingAnimation.exit}
        transition={flyingAnimation.transition}
        className='flex flex-col justify-center items-center gap-y-5'
      >
        {children}
      </motion.div>
    );
};

interface componentMap {
    [key: string]: React.ReactNode;
}

const Valentines = () => {
    const [currentComponent, setCurrentComponent] = useState("flowers");
    const [lastCorrentComponent, setLastCorrectComponent] = useState("correct3");
    const [lastWrongComponent, setLastWrongComponent] = useState("wrong1");

    const headerClassName = "text-4xl font-bold mb-5";
    const wrongTimer = 2100;
    const timeBetweenTransitions = 2000;
    const wrongComponents = ["wrong1", "wrong2", "wrong3", "wrong4"];
    const correctComponents = ["correct1", "correct3", "correct4", "correct5", "correct6", "correct7", "correct8"];
    const skipTransition = ["question", "flowers", "dinner", "almostdone", "lastquestion", "pheasant", "squirrel"]

    const handleClick = (nextComponent: string) => {
        // check if the component name starts with "wrong"
        if (nextComponent.startsWith("wrong")) {
            // choose a random wrong component and make sure it is not the same as the last one
            let randomIndex = Math.floor(Math.random() * wrongComponents.length);
            while (lastWrongComponent === wrongComponents[randomIndex]) {
                randomIndex = Math.floor(Math.random() * wrongComponents.length);
            }
            setLastWrongComponent(wrongComponents[randomIndex]);
            setCurrentComponent(wrongComponents[randomIndex]);

            setTimeout(() => {
                setCurrentComponent("transition-wrong");
            }, wrongTimer);

            setTimeout(() => {
                setCurrentComponent("flowers");
            }, wrongTimer + timeBetweenTransitions);
        } else {
            // check if the component name is in the skip transition list
            if (skipTransition.includes(currentComponent)) {
                setCurrentComponent(nextComponent);
            } else {
                // choose a random correct component and make sure it is not the same as the last one
                let randomIndex = Math.floor(Math.random() * correctComponents.length);
                while (lastCorrentComponent === correctComponents[randomIndex]) {
                    randomIndex = Math.floor(Math.random() * correctComponents.length);
                }
                setLastCorrectComponent(correctComponents[randomIndex]);
                setCurrentComponent(correctComponents[randomIndex]);

                setTimeout(() => {
                    setCurrentComponent(nextComponent);
                }, wrongTimer);
            }
        }
    };

    const components: componentMap = {
        "flowers": (
            <AnimatedBlock key={"flowers"}>
                <h1 className={headerClassName}>can i send you flowers?</h1>
                <div className='flex flex-row justify-center items-center gap-x-5 gap-y-5'>
                    <button onClick={() => handleClick("dinner")} className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-5'>duh!!!</button>
                    <button onClick={() => handleClick("wrong")} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>nuh uh</button>
                </div>
            </AnimatedBlock>
        ),
        "dinner": (
            <AnimatedBlock key={"dinner"}>
                <h1 className={headerClassName}>can we have dinner?</h1>
                <div className='flex flex-row justify-center items-center gap-x-5 gap-y-5'>
                    <button onClick={() => handleClick("pheasant")} className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-5'>mexican?</button>
                    <button onClick={() => handleClick("wrong")} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>freak no</button>
                </div>
            </AnimatedBlock>
        ),
        "pheasant": (
            <AnimatedBlock key={"pheasant"}>
                <img src='img/pheasant.jpg' alt='pheasant' style={{height: '20vh'}} />
                <h1 className={headerClassName}>is this a chicken?</h1>
                <div className='flex flex-row justify-center items-center gap-x-5 gap-y-5'>
                    <button onClick={() => handleClick("squirrel")} className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-5'>yes!!</button>
                    <button onClick={() => handleClick("wrong")} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>ew</button>
                </div>
            </AnimatedBlock>
        ),
        "squirrel": (
            <AnimatedBlock key={"squirrel"}>
                <img src='img/squirrel.gif' alt='squirrel' style={{height: '20vh'}}/>
                <h1 className={headerClassName}>isn't this cool? 😎</h1>
                <div className='flex flex-row justify-center items-center gap-x-5 gap-y-5'>
                    <button onClick={() => handleClick("favoritenumber")} className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-5'>LOVE LOVE</button>
                    <button onClick={() => handleClick("wrong")} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>what is that??</button>
                </div>
            </AnimatedBlock>
        ),
        "favoritenumber": (
            <AnimatedBlock key={"favoritenumber"}>
                <h1 className={headerClassName}>what was the name of my betta fish?</h1>
                <div className='flex flex-row justify-center items-center flex-wrap gap-x-5 gap-y-5'>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>taco</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>long john silver</button>
                    <button onClick={() => handleClick("favoriteanime")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>peaches</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>fish</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>master shifu</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>mr. fish</button>
                </div>
            </AnimatedBlock>
        ),
        "favoriteanime": (
            <AnimatedBlock key={"favoriteanime"}>
                <h1 className={headerClassName}>whats my favorite anime?</h1>
                <div className='flex flex-row justify-center items-center flex-wrap gap-x-5 gap-y-5'>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>cowboy bebop</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>full metal alchemist</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>demon slayer</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>pokemon</button>
                    <button onClick={() => handleClick("soccerposition")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>steins;gate</button>
                    <button onClick={() => handleClick("wrong")} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>i hate anime</button>
                </div>
            </AnimatedBlock>
        ),
        "soccerposition": (
            <AnimatedBlock key={"soccerposition"}>
                <h1 className={headerClassName}>what position did i play in soccer?</h1>
                <div className='flex flex-row justify-center items-center flex-wrap gap-x-5 gap-y-5'>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>goalie</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>winger</button>
                    <button onClick={() => handleClick("solvethis")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>striker</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>defender</button>
                </div>
            </AnimatedBlock>
        ),
        "solvethis": (
            <AnimatedBlock key={"solvethis"}>
                <h1 className={headerClassName}>solve this.</h1>
                <p className='text-xs'>(2 decimal places)</p>
                <img src='img/solvethis.png' alt='solvethis' style={{width: '50vw'}} />
                <div className='flex flex-row justify-center items-center flex-wrap gap-x-5 gap-y-5'>
                    <AnswerInput correctAnswer='13.33' onCorrectAnswer={() => handleClick("favoritecolor")} />
                </div>
            </AnimatedBlock>
        ),
        "favoritecolor": (
            <AnimatedBlock key={"favoritecolor"}>
                <h1 className={headerClassName}>okay easy one. whats my favorite color?</h1>
                <div className='flex flex-row justify-center items-center flex-wrap gap-x-5 gap-y-5'>
                    {/* render several variations of the color green */}
                    <ColorButton color='#00ff00' onClick={() => handleClick("wrong")} />
                    <ColorButton color='#00ee00' onClick={() => handleClick("wrong")} />
                    <ColorButton color='#00dd00' onClick={() => handleClick("wrong")} />
                    <ColorButton color='#00cc00' onClick={() => handleClick("wrong")} />
                    <ColorButton color='#00bb00' onClick={() => handleClick("wrong")} />
                    <ColorButton color='#00aa00' onClick={() => handleClick("wrong")} />
                    <ColorButton color='#009900' onClick={() => handleClick("wrong")} />
                    <ColorButton color='#008800' onClick={() => handleClick("wrong")} />
                    <ColorButton color='#007700' onClick={() => handleClick("wrong")} />
                    <ColorButton color='#006600' onClick={() => handleClick("favoriteword")} />
                    <ColorButton color='#005500' onClick={() => handleClick("wrong")} />
                    <ColorButton color='#004400' onClick={() => handleClick("wrong")} />
                    <ColorButton color='#003300' onClick={() => handleClick("wrong")} />
                    <ColorButton color='#002200' onClick={() => handleClick("wrong")} />
                    <ColorButton color='#001100' onClick={() => handleClick("wrong")} />
                    <ColorButton color='#000000' onClick={() => handleClick("wrong")} />
                </div>
            </AnimatedBlock>
        ),
        "favoriteword": (
            <AnimatedBlock key={"favoriteword"}>
                <h1 className={headerClassName}>whats my favorite word?</h1>
                <div className='flex flex-row justify-center items-center flex-wrap gap-x-5 gap-y-5'>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>ditto</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>spaghetti</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>bolognese</button>
                    <button onClick={() => handleClick("buglist")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>non chalant</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>chalant</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>poggers</button>
                </div>
            </AnimatedBlock>
        ),
        "buglist": (
            <AnimatedBlock key={"buglist"}>
                <h1 className={headerClassName}>what item did i put on our buglist?</h1>
                <div className='flex flex-row justify-center items-center flex-wrap gap-x-5 gap-y-5'>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>cable managemen</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>zipper comes down</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>oil leaking while using it to cook</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>transporting toiletries when you travel</button>
                    <button onClick={() => handleClick("eatingcolor")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>washing hand in public restroom, water on counter</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>washing cups</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>washing a spoon, splashing clothes</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>losing glasses</button>
                </div>
            </AnimatedBlock>
        ),
        "eatingcolor": (
            <AnimatedBlock key={"eatingcolor"}>
                <h1 className={headerClassName}>what color would i eat?</h1>
                <div className='flex flex-row justify-center items-center flex-wrap gap-x-5 gap-y-5'>
                    <ColorButton color='green' onClick={() => handleClick("wrong")} />
                    <ColorButton color='red' onClick={() => handleClick("wrong")} />
                    <ColorButton color='purple' onClick={() => handleClick("wrong")} />
                    <ColorButton color='yellow' onClick={() => handleClick("wrong")} />
                    <ColorButton color='blue' onClick={() => handleClick("wrong")} />
                    <ColorButton color='orange' onClick={() => handleClick("lovemeter")} />
                    <ColorButton color='pink' onClick={() => handleClick("wrong")} />
                </div>
            </AnimatedBlock>
        ),
        "lovemeter": (
            <AnimatedBlock key={"lovemeter"}>
                <h1 className={headerClassName}>how much do i love you?</h1>
                <NumberSlider correctNumber={100} onCorrectAnswer={() => handleClick("computergpu")} />
            </AnimatedBlock>
        ),
        "computergpu": (
            <AnimatedBlock key={"computergpu"}>
                <h1 className={headerClassName}>what graphics card do i have in my computer?</h1>
                <div className='flex flex-row justify-center items-center flex-wrap gap-x-5 gap-y-5'>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>ryzen 7 2700x</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>ryzen 9 2800x</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>idk</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>gtx 1060</button>
                    <button onClick={() => handleClick("peanutsincoke")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>rtx 2060</button>
                    <button onClick={() => handleClick("wrong")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>rtx 3060</button>
                </div>
            </AnimatedBlock>
        ),
        "peanutsincoke": (
            <AnimatedBlock key={"peanutsincoke"}>
                <h1 className={headerClassName}>are peanuts good in coke?</h1>
                <div className='flex flex-row justify-center items-center flex-wrap gap-x-5 gap-y-5'>
                    <button onClick={() => handleClick("almostdone")} className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded'>SO GOOD!!!</button>
                    <button onClick={() => handleClick("wrong")} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>GROSS!!!!</button>
                </div>
            </AnimatedBlock>
        ),
        "almostdone": (
            <AnimatedBlock key={"almostdone"}>
                <h1 className="text-3xl font-bold">wow you really do love me.</h1>
                <h3 className="text-2xl mb-5">(i love you too.)</h3>
                <p className="text-lg">i have two more questions.</p>
                <button onClick={() => handleClick("question")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>okay...</button>
            </AnimatedBlock>
        ),
        "question": (
            <AnimatedBlock key={"question"}>
                <h1 className={headerClassName}>will you be my valentine?</h1>
                <div className='flex flex-row justify-center items-center gap-x-5 gap-y-5'>
                    <button onClick={() => handleClick("lastquestion")} className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-5'>yes :)</button>
                    <button onClick={() => handleClick("lastquestion")} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>also yes</button>
                </div>
            </AnimatedBlock>
        ),
        "lastquestion": (
            <AnimatedBlock key={"lastquestion"}>
                <h1 className={headerClassName}>can i be your valentine?</h1>
                <div className='flex flex-row justify-center items-center gap-x-5 gap-y-5'>
                    <button onClick={() => handleClick("final")} className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-5'>yes :)</button>
                    <button onClick={() => handleClick("final")} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>no</button>
                </div>
            </AnimatedBlock>
        ),
        "final": (
            <AnimatedBlock key={"final"}>
                <h1 className="text-3xl font-bold">good i can't wait :)</h1>
                <p className='text-sm'>i miss you.</p>
                <p className='text-sm'>p.s. please figure out how i can send you packages :))</p>
                <p className='text-sm mt-20'>p.p.s. heres a picture i drew</p>
                <img src='img/wowza.png' alt='us' style={{width: '50vw'}} />
            </AnimatedBlock>
        ),
        "correct1": (
            <AnimatedBlock key={"correct1"}>
                <h1 className={headerClassName}>good job!!</h1>
            </AnimatedBlock>
        ),
        "correct3": (
            <AnimatedBlock key={"correct3"}>
                <h1 className={headerClassName}>im proud of you.</h1>
            </AnimatedBlock>
        ),
        "correct4": (
            <AnimatedBlock key={"correct4"}>
                <h1 className={headerClassName}>amazing. heres a reward.</h1>
                <img src='img/cashew.webp' alt='cashew' style={{width: '50vw'}} />
            </AnimatedBlock>
        ),
        "correct5": (
            <AnimatedBlock key={"correct5"}>
                <h1 className={headerClassName}>you're literally the smartest woman in the world</h1>
            </AnimatedBlock>
        ),
        "correct6": (
            <AnimatedBlock key={"correct6"}>
                <h1 className={headerClassName}>perfect.</h1>
            </AnimatedBlock>
        ),
        "correct7": (
            <AnimatedBlock key={"correct7"}>
                <h1 className={headerClassName}>hooty hoo!!</h1>
            </AnimatedBlock>
        ),
        "correct8": (
            <AnimatedBlock key={"correct8"}>
                <h1 className={headerClassName}>ditto!</h1>
            </AnimatedBlock>
        ),
        "wrong1": (
            <AnimatedBlock key={"wrong1"}>
                <h1 className={headerClassName}>fine.</h1>
            </AnimatedBlock>
        ),
        "wrong2": (
            <AnimatedBlock key={"wrong2"}>
                <h1 className={headerClassName}>you're breaking my heart.</h1>
            </AnimatedBlock>
        ),
        "wrong3": (
            <AnimatedBlock key={"wrong3"}>
                <h1 className={headerClassName}>wow. i can't believe this.</h1>
            </AnimatedBlock>
        ),
        "wrong4": (
            <AnimatedBlock key={"wrong4"}>
                <h1 className={headerClassName}>i'm very disappointed.</h1>
            </AnimatedBlock>
        ),
        "transition-wrong": (
            <AnimatedBlock key={"transition-wrong"}>
                <h1 className={headerClassName}>try again.</h1>
            </AnimatedBlock>
        )
    };

    return (
        <motion.div className='flex flex-col justify-center items-center' 
                initial={flyingAnimation.initial}
                animate={flyingAnimation.animate}
                exit={flyingAnimation.exit}
                transition={flyingAnimation.transition}
            >
                <AnimatePresence>
                    {components[currentComponent]}
                </AnimatePresence>
        </motion.div>
    );
};

export default Valentines;