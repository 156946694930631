import React, { useEffect, useState } from 'react';
import '../../css/Flowers.scss';
import { getFlowerColor, createFlowerColor } from '../../firebase/Helpers';
import { FlowerColorModel } from '../../firebase/FlowerColorModel';

const calculateDarkerShader = (color: string, percent: number) => {
    const f = parseInt(color.slice(1), 16);
    const t = percent < 0 ? 0 : 255;
    const p = percent < 0 ? percent * -1 : percent;
    const R = f >> 16;
    const G = (f >> 8) & 0x00FF;
    const B = f & 0x0000FF;
    return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
}


const Flowers = () => {

    const [flowerModel, setFlowerModel] = useState({ color: 'red', date: new Date() } as FlowerColorModel);

    const handleFlowerModel = (model: FlowerColorModel | null) => {
        // check if the date of the model is on the same day as today
        // if not, get a new random color and update the model and save it to the database
        const today = new Date();
        if (model == null || (model.date.getDate() !== today.getDate())) {
            model = {} as FlowerColorModel;

            const randomColor = '#' + Math.floor(Math.random()*16777215).toString(16);
            model.color = randomColor;
            model.date = today;

            setFlowerModel(model);

            createFlowerColor(model).then(() => {
                console.log('Flower color saved successfully');
            }).catch((error) => {
                console.error(error);
            });
        }

        setFlowerModel(model);
    }

    useEffect(() => {
        getFlowerColor().then((model) => {
            handleFlowerModel(model);
        }).catch((error) => {
            console.error(error);
            handleFlowerModel(null);
        });
    }, [])
 
    // React useEffect hook that will fire up
    // when "darkTheme" changes
    useEffect(() => {
        // Accessing scss variable "--background-color"
        // and "--text-color" using plain JavaScript
        // and changing the same according to the state of "darkTheme"
        const root = document.getElementById("root");

        root?.style.setProperty("--flower-color", flowerModel.color);
        root?.style.setProperty("--flower-color-2", calculateDarkerShader(flowerModel.color, 0.5));

        root?.style.setProperty("--particle-color-1", calculateDarkerShader(flowerModel.color, 0.8));
        root?.style.setProperty("--particle-color-2", calculateDarkerShader(flowerModel.color, 0.2));

        root?.style.setProperty("--white-circle-color", calculateDarkerShader(flowerModel.color, 0.3));

    }, [flowerModel]);

    return (
        <div className='flower-container'>
            <div className="night"></div>
            <div className="flowers">
                <div className="flower flower--1">
                <div className="flower__leafs flower__leafs--1">
                    <div className="flower__leaf flower__leaf--1"></div>
                    <div className="flower__leaf flower__leaf--2"></div>
                    <div className="flower__leaf flower__leaf--3"></div>
                    <div className="flower__leaf flower__leaf--4"></div>
                    <div className="flower__white-circle"></div>

                    <div className="flower__light flower__light--1"></div>
                    <div className="flower__light flower__light--2"></div>
                    <div className="flower__light flower__light--3"></div>
                    <div className="flower__light flower__light--4"></div>
                    <div className="flower__light flower__light--5"></div>
                    <div className="flower__light flower__light--6"></div>
                    <div className="flower__light flower__light--7"></div>
                    <div className="flower__light flower__light--8"></div>

                </div>
                <div className="flower__line">
                    <div className="flower__line__leaf flower__line__leaf--1"></div>
                    <div className="flower__line__leaf flower__line__leaf--2"></div>
                    <div className="flower__line__leaf flower__line__leaf--3"></div>
                    <div className="flower__line__leaf flower__line__leaf--4"></div>
                    <div className="flower__line__leaf flower__line__leaf--5"></div>
                    <div className="flower__line__leaf flower__line__leaf--6"></div>
                </div>
                </div>

                <div className="flower flower--2">
                <div className="flower__leafs flower__leafs--2">
                    <div className="flower__leaf flower__leaf--1"></div>
                    <div className="flower__leaf flower__leaf--2"></div>
                    <div className="flower__leaf flower__leaf--3"></div>
                    <div className="flower__leaf flower__leaf--4"></div>
                    <div className="flower__white-circle"></div>

                    <div className="flower__light flower__light--1"></div>
                    <div className="flower__light flower__light--2"></div>
                    <div className="flower__light flower__light--3"></div>
                    <div className="flower__light flower__light--4"></div>
                    <div className="flower__light flower__light--5"></div>
                    <div className="flower__light flower__light--6"></div>
                    <div className="flower__light flower__light--7"></div>
                    <div className="flower__light flower__light--8"></div>

                </div>
                <div className="flower__line">
                    <div className="flower__line__leaf flower__line__leaf--1"></div>
                    <div className="flower__line__leaf flower__line__leaf--2"></div>
                    <div className="flower__line__leaf flower__line__leaf--3"></div>
                    <div className="flower__line__leaf flower__line__leaf--4"></div>
                </div>
                </div>

                <div className="flower flower--3">
                <div className="flower__leafs flower__leafs--3">
                    <div className="flower__leaf flower__leaf--1"></div>
                    <div className="flower__leaf flower__leaf--2"></div>
                    <div className="flower__leaf flower__leaf--3"></div>
                    <div className="flower__leaf flower__leaf--4"></div>
                    <div className="flower__white-circle"></div>

                    <div className="flower__light flower__light--1"></div>
                    <div className="flower__light flower__light--2"></div>
                    <div className="flower__light flower__light--3"></div>
                    <div className="flower__light flower__light--4"></div>
                    <div className="flower__light flower__light--5"></div>
                    <div className="flower__light flower__light--6"></div>
                    <div className="flower__light flower__light--7"></div>
                    <div className="flower__light flower__light--8"></div>

                </div>
                <div className="flower__line">
                    <div className="flower__line__leaf flower__line__leaf--1"></div>
                    <div className="flower__line__leaf flower__line__leaf--2"></div>
                    <div className="flower__line__leaf flower__line__leaf--3"></div>
                    <div className="flower__line__leaf flower__line__leaf--4"></div>
                </div>
                </div>

                <div className="grow-ans" style={{ "--d": "1.2s" } as React.CSSProperties}>
                <div className="flower__g-long">
                    <div className="flower__g-long__top"></div>
                    <div className="flower__g-long__bottom"></div>
                </div>
                </div>

                <div className="grow-ans" style={{ "--d": "2.4s" } as React.CSSProperties}>
                <div className="flower__g-right flower__g-right--1">
                    <div className="leaf"></div>
                </div>
                </div>

                <div className="grow-ans" style={{ "--d": "2.8s" } as React.CSSProperties}>
                <div className="flower__g-right flower__g-right--2">
                    <div className="leaf"></div>
                </div>
                </div>

                <div className="grow-ans" style={{ "--d": "2.8s" } as React.CSSProperties}>
                <div className="flower__g-front">
                    <div className="flower__g-front__leaf-wrapper flower__g-front__leaf-wrapper--1">
                    <div className="flower__g-front__leaf"></div>
                    </div>
                    <div className="flower__g-front__leaf-wrapper flower__g-front__leaf-wrapper--2">
                    <div className="flower__g-front__leaf"></div>
                    </div>
                    <div className="flower__g-front__leaf-wrapper flower__g-front__leaf-wrapper--3">
                    <div className="flower__g-front__leaf"></div>
                    </div>
                    <div className="flower__g-front__leaf-wrapper flower__g-front__leaf-wrapper--4">
                    <div className="flower__g-front__leaf"></div>
                    </div>
                    <div className="flower__g-front__leaf-wrapper flower__g-front__leaf-wrapper--5">
                    <div className="flower__g-front__leaf"></div>
                    </div>
                    <div className="flower__g-front__leaf-wrapper flower__g-front__leaf-wrapper--6">
                    <div className="flower__g-front__leaf"></div>
                    </div>
                    <div className="flower__g-front__leaf-wrapper flower__g-front__leaf-wrapper--7">
                    <div className="flower__g-front__leaf"></div>
                    </div>
                    <div className="flower__g-front__leaf-wrapper flower__g-front__leaf-wrapper--8">
                    <div className="flower__g-front__leaf"></div>
                    </div>
                    <div className="flower__g-front__line"></div>
                </div>
                </div>

                <div className="grow-ans" style={{ "--d": "3.2s" } as React.CSSProperties}>
                <div className="flower__g-fr">
                    <div className="leaf"></div>
                    <div className="flower__g-fr__leaf flower__g-fr__leaf--1"></div>
                    <div className="flower__g-fr__leaf flower__g-fr__leaf--2"></div>
                    <div className="flower__g-fr__leaf flower__g-fr__leaf--3"></div>
                    <div className="flower__g-fr__leaf flower__g-fr__leaf--4"></div>
                    <div className="flower__g-fr__leaf flower__g-fr__leaf--5"></div>
                    <div className="flower__g-fr__leaf flower__g-fr__leaf--6"></div>
                    <div className="flower__g-fr__leaf flower__g-fr__leaf--7"></div>
                    <div className="flower__g-fr__leaf flower__g-fr__leaf--8"></div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Flowers;