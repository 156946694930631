import { Stage, Container, Sprite, Text, useApp, useTick } from '@pixi/react';
import Leaderboard from './Leaderboard';
import { useEffect, useState } from 'react';
import { HighScoreModel } from '../../../firebase/HighscoreModel';
import { getHighScores, saveHighScore, getIP } from '../../../firebase/Helpers';
import { Timestamp } from 'firebase/firestore';

interface GameOverProps {
    score: number
    onRestart: () => void
}

const GameOver = (props: GameOverProps) => {

    const [entries, setEntries] = useState<HighScoreModel[]>([]);
    const [saved, setSaved] = useState(false);
    const [name, setName] = useState('');

    useEffect(() => {
        getHighScores(10).then((data) => {
            setEntries(data);
            console.log(data);
        });
    }, [saved]);

    const handleSave = () => {
        let model: HighScoreModel = {
            date: Timestamp.now(),
            score: props.score,
            name: name,
            ip: ''
        };

        getIP().then((ip) => {
            model.ip = ip;
            saveHighScore(model).then(() => {
                setSaved(true);
                setEntries([...entries, model]);
            });
        });
    }

    const renderSaveDialog = () => {
        return (
            <div className='flex flex-row justify-evenly items-center'>
                <input 
                    type='text' 
                    placeholder='Name' 
                    className='border border-gray-300 rounded-md p-2' 
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' onClick={handleSave}>Save</button>
            </div>
        )
    }

    const renderSaveConfirmation = () => {
        return (
            <p className='text-green-500'>Score saved!</p>
        )
    }

    return (
        <div className='flex flex-col justify-between min-h-1/2 items-center gap-10'>


            <Leaderboard entrys={entries}/>

            <div className='flex flex-col justify-center items-center gap-5'>

                <p className='text-4xl'>Score: {props.score}</p>

                {!saved ? renderSaveDialog() : renderSaveConfirmation()}

            </div>

            <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' onClick={props.onRestart}>Restart?</button>

            
        </div>
    );
}

export default GameOver;