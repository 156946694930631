import React, { useState } from 'react';
import {motion} from 'framer-motion';
import { flyingAnimation } from '../../animation.config';
import { SquirrelStage } from '../squirrel-game/SquirrelStage';

const Game = () => {

    return (
        <motion.div className='flex flex-col justify-center items-center' 
            initial={flyingAnimation.initial}
            animate={flyingAnimation.animate}
            exit={flyingAnimation.exit}
            transition={flyingAnimation.transition}
        >
            <SquirrelStage />
        </motion.div>
    );
};

export default Game;