import React from 'react';
import {motion} from 'framer-motion';

import HeartComponent from '../HeartComponent';
import { flyingAnimation } from '../../animation.config';

const LoveComponent = () => {
    const sCount = 10000; // Adjust this value as needed
    const breakPoints = [
        {
            num: 1000,
            text: 'hold on i need some water 🥤',
        },
        {
            num: 1001,
            text: 'AHEM'
        },
        {
            num: 2000,
            text: 'you have the prettiest smile'
        },
        {
            num: 2453,
            text: '🐿️🐿️🐿️'
        },
        {
            num: 3000,
            text: 'the kindest heart'
        },
        {
            num: 3492,
            text: 'you can take a break if your fingers are tired'
        },
        {
            num: 3800,
            text: 'my best friend'
        },
        {
            num: 4600,
            text: 'im literally just typing s'
        },
        {
            num: 5600,
            text: 'the most perfect girl'
        },
        {
            num: 6359,
            text: 'MYYYYYYYY WAYYYYYYYYY'
        },
        {
            num: 6360,
            text: '🎶🎶🎶🎶🎶🎶'
        },
        {
            num: 7777,
            text: 'HOOTY HOO HOOTY HOO (7777th)'
        },
        {
            num: 8000,
            text: 'the most hard working person i know'
        },
        {
            num: 8300,
            text: 'i miss you. a lot'
        },
        {
            num: 8500,
            text: 'the kind of laugh melts my heart'
        },
        {
            num: 8600,
            text: 'a smile that gives me butterflies'
        },
        {
            num: 9000,
            text: 'eyes i can see my future in'
        },
        {
            num: 9100,
            text: 'a voice that brings me home'
        },
        {
            num: 9300,
            text: 'you bring out parts of me i havent seen since i was a kid'
        },
        {
            num: 9500,
            text: 'the most beautiful soul.'
        },
        {
            num: 9800,
            text: 'you mean the world to me'
        },
    ]; // Adjust this value as needed
    const sArray = new Array(sCount).fill('s');
  
    return (
      <div className='flex flex-col text-center items-center justify-center text-4xl bg-gradient-to-r from-red-400 to-pink-800 inline-block text-transparent bg-clip-text'>
        <p>hey i just wanted to let you know</p>
        <p className='text-6xl'>i love you</p>
        <p>this</p>
        {
            sArray.map((s, index) => {
                const breakPoint = breakPoints.find(bp => bp.num === index);
                if (breakPoint) {
                    return (
                        <p key={index} className='text-6xl bg-gradient-to-r from-cyan-400 to-green-800 inline-block text-transparent bg-clip-text'>{breakPoint.text}</p>
                    );
                }
                else {
                    // determine the group of 5 that the "s" character belongs to
                    const group = Math.floor(index / 5) % 5;
                    // set the font size based on the group
                    const fontSize = group + 3; // this will give a value from 1 to 5
                    return <p key={index} className={`text-${fontSize}xl`}>{s}</p>
                }
            })
        }
        <p>much</p>
        <p className='text-7xl bg-gradient-to-r from-red-800 to-pink-100 inline-block text-transparent bg-clip-text'>(more)</p>
      </div>
    );
};

const ForAudrey = () => {
    return (
        <motion.div className='flex flex-col justify-center items-center' 
            initial={flyingAnimation.initial}
            animate={flyingAnimation.animate}
            exit={flyingAnimation.exit}
            transition={flyingAnimation.transition}
        >
            <LoveComponent />
            <HeartComponent />
            <p className='text-md'>yeah this was pretty cheesy</p>
            <hr className='w-1/2' />
            <p className='text-xs text-cyan-400'>for the most beautiful girl in the world</p>
        </motion.div>
    );
};


export default ForAudrey;