import React, { useState } from 'react';
import {motion} from 'framer-motion';
import { flyingAnimation } from '../../animation.config';

const Squirrel = () => {

    return (
        <motion.div className='flex flex-col justify-center items-center' 
            initial={flyingAnimation.initial}
            animate={flyingAnimation.animate}
            exit={flyingAnimation.exit}
            transition={flyingAnimation.transition}
        >
            <img src='img/squirrel.gif' alt='A squirrel' />
            <p className='text-4xl font-bold text-center rainbow-text'>
                party squirrel!!!
            </p>
            <p className='text-2xl text-center mt-14'>
                🐿️
            </p>
        </motion.div>
    );
};

export default Squirrel;