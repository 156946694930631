import { Container, Text, Sprite } from "@pixi/react"
import { InitiatedPowerupModel } from "../Powerup.model"


interface PowerupsDisplayProps {
    powerups: InitiatedPowerupModel[]
}

interface PowerupStats {
    name: string
    image: string
    count: number
}

const PowerupsDisplay = (props: PowerupsDisplayProps) => {

    const getPowerupStats = (): PowerupStats[] => {
        const powerupStats: PowerupStats[] = [];

        props.powerups.forEach(powerup => {
            const existingStat = powerupStats.find(p => p.name === powerup.type);

            if(existingStat) {
                existingStat.count++;
            } else {
                powerupStats.push({
                    name: powerup.type,
                    image: powerup.image,
                    count: 1
                });
            }
        });

        return powerupStats;
    }

    return (
        <Container 
            position={{x: 0, y: 0}}
        >
            <Text text="Powerups:"/>

            {getPowerupStats().map((stat, index) => (
                <Container
                    key={stat.name}
                    position={{x: 0, y: 0}}
                >
                    <Text text={`${stat.name} x${stat.count}`} y={(index + 1) * 30}/>
                </Container>
            ))}
            
        </Container>
    )

}

export default PowerupsDisplay