
export interface Key {
    value: string;
    isDown: boolean;
    isUp: boolean;
    press: () => void;
    release: () => void;
    downHandler: (event: KeyboardEvent) => void;
    upHandler: (event: KeyboardEvent) => void;
    destroy?: () => void;
}

export const Keyboard = (value: string): Key => {
    const key: Key = {
        value: value,
        isDown: false,
        isUp: true,
        press: () => {},
        release: () => {},
        downHandler: (event: KeyboardEvent) => {
            if (event.key === key.value) {
                if (key.isUp && key.press) key.press();
                key.isDown = true;
                key.isUp = false;
                event.preventDefault();
            }
        },
        upHandler: (event: KeyboardEvent) => {
            if (event.key === key.value) {
                if (key.isDown && key.release) key.release();
                key.isDown = false;
                key.isUp = true;
                event.preventDefault();
            }
        }
    };

    window.addEventListener("keydown", key.downHandler.bind(key), false);
    window.addEventListener("keyup", key.upHandler.bind(key), false);

    key.destroy = () => {
        window.removeEventListener("keydown", key.downHandler.bind(key));
        window.removeEventListener("keyup", key.upHandler.bind(key));
    };

    return key;
}