import starbucksCup from '../../assets/starbucks.png'
import acorn from '../../assets/acorn.png'
import clock from '../../assets/clock.png'


export type PowerupType = 'speed' | 'size' | 'time'

export interface PowerupModel {
    type: PowerupType
    image: string
    duration: number
    color: number
    spawnRate: number
    timer?: NodeJS.Timeout
    renderScale?: number
    previousValue?: number
}

export interface InitiatedPowerupModel extends PowerupModel {
    id: number
}

export const Powerups: Record<PowerupType, PowerupModel> = {
    'speed': {
        type: 'speed',
        image: starbucksCup,
        renderScale: 0.05,
        duration: 8000,
        color: 0x00ff00,
        spawnRate: 0.20,
    },
    'size': {
        type: 'size',
        image: acorn,
        renderScale: 0.10,
        duration: 8000,
        color: 0xa3774e,
        spawnRate: 0.20
    },
    'time': {
        type: 'time',
        image: clock,
        renderScale: 0.10,
        duration: 5000,
        color: 0x000000,
        spawnRate: 0.05
    }
}
