import React, { ElementRef } from "react";
import { GlowFilter } from '@pixi/filter-glow';
import { Sprite, Container, useTick, withFilters } from "@pixi/react";
import cashewImage from "../../../assets/cashew.png";

interface CashewProps {
    cashewRef: React.RefObject<ElementRef<typeof Sprite>>
    squirrelRef: React.RefObject<ElementRef<typeof Sprite>>
    onCollide: () => void
    position: { x: number, y: number }
    rotation: number
    isGolden: boolean
}

const Cashew = (props: CashewProps) => {

  const Filters = withFilters(Container, {
    glow: GlowFilter,
  })

  useTick(() => {
    if(props.squirrelRef.current && props.cashewRef.current) {
        const squirrelBounds = props.squirrelRef.current.getBounds();
        const cashewBounds = props.cashewRef.current.getBounds();
    
        if(squirrelBounds.intersects(cashewBounds)) {
            props.onCollide();
        }
    }
  });


  return (
    <Filters 
      glow={{outerStrength: props.isGolden ? 20 : 0, innerStrength: 0, color: 0xe7f20c, alpha: 0.7}}
    >
      <Sprite
          ref={props.cashewRef}
          image={cashewImage}
          x={props.position.x}
          y={props.position.y}
          anchor={{ x: 0.5, y: 0.5 }}
          scale={{ x: 0.2, y: 0.2 }}
          rotation={props.rotation}
        />
    </Filters>
  );

};

export default Cashew;
