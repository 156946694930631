import React, { useEffect, useState } from 'react';

interface CountdownProps {
    targetDate: string;
}

interface TimeLeft {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

const Countdown = (props: CountdownProps) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(props.targetDate) - +new Date();
    let timeLeft: TimeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
    <div className="text-center flex flex-row items-center justify-center">
      {Object.entries(timeLeft).map(([unit, value]) => (
        <div key={unit} className="inline-block m-2 p-2 border border-gray-200 rounded">
          <div className="text-2xl">{value}</div>
          <div>{unit}</div>
        </div>
      ))}
    </div>
  );
};

export default Countdown;