import { Rectangle } from "@pixi/math";


export const getRandomRectangle = (widthBound: number, heightBound: number, width: number, height: number): Rectangle => {
    let rect =  Rectangle.EMPTY;

    let centerX = Math.random() * widthBound;
    let centerY = Math.random() * heightBound;

    rect.x = centerX - width / 2;
    rect.y = centerY - height / 2;
    rect.width = width;
    rect.height = height;

    return rect;
}

/** 
Given an array of numbers, which are the probabilities of each index, return a random index
If the numbers do not add up to one, then the left over probability will be treated as null
*/
export const pickRandom = (probabilities: number[]): number | null => {
    const sumProbabilities = probabilities.reduce((acc, prob) => acc + prob, 0);

    // Calculate the leftover probability
    const leftoverProbability = 1 - sumProbabilities;

    // Extend the probabilities array to account for the leftover probability
    const extendedProbabilities = [...probabilities, leftoverProbability];

    // Generate a random number between 0 and 1
    const randomValue = Math.random();

    // Determine which index is picked based on the random number and the cumulative probabilities
    let cumulativeProbability = 0;

    for (let i = 0; i < extendedProbabilities.length; i++) {
        cumulativeProbability += extendedProbabilities[i];
        if (randomValue <= cumulativeProbability) {
            return i < probabilities.length ? i : null;
        }
    }

    // If something goes wrong (which shouldn't happen), return null
    return null;
}
