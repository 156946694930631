import React, { ElementRef, useEffect, useRef, useState } from "react";
import { GlowFilter } from '@pixi/filter-glow';
import { Stage, Sprite, Container, useApp, useTick, withFilters } from "@pixi/react";
import { InitiatedPowerupModel } from "../Powerup.model";


export interface PowerupConfig {
    powerup: InitiatedPowerupModel
    position: { x: number, y: number }
    rotation: number
}

interface PowerUpProps {
    config: PowerupConfig
    squirrelRef: React.RefObject<ElementRef<typeof Sprite>>
    onCollide: () => void
}

const PowerUp = (props: PowerUpProps) => {

    const itemRef = useRef<ElementRef<typeof Sprite>>(null);

    const Filters = withFilters(Container, {
        glow: GlowFilter,
    })

  useTick(() => {
    if(props.squirrelRef.current && itemRef.current) {
        const squirrelBounds = props.squirrelRef.current.getBounds();
        const cashewBounds = itemRef.current.getBounds();
    
        if(squirrelBounds.intersects(cashewBounds)) {
            props.onCollide();
        }
    }
  });

  const getRenderScale = () => {
    return props.config.powerup.renderScale || 0.2;
  }

  return (
    <Filters 
      glow={{outerStrength: 20, innerStrength: 0, color: props.config.powerup.color, alpha: 0.7}}
    >
      <Sprite
          ref={itemRef}
          image={props.config.powerup.image}
          x={props.config.position.x}
          y={props.config.position.y}
          anchor={{ x: 0.5, y: 0.5 }}
          scale={{ x: getRenderScale(), y: getRenderScale() }}
          rotation={props.config.rotation}
        />
    </Filters>
  );

};

export default PowerUp;
