import Flowers from './components/pages/Flowers';
import ForAudrey from './components/pages/ForAudrey';
import Game from './components/pages/Game';
import Squirrel from './components/pages/Squirrel';
import Valentines from './components/pages/Valentines';
import ValentinesDay from './components/pages/ValentinesDay';

// Hashed passwords for demonstration purposes
const ForAudreyPassword = 'e9f2a0186210e30a516d12b001717fc17b1887acad69faf5c2141067f3f6b094'; //sunrise
const SquirrelPassword = '960a38ace75a2fe9426aa5d48b536cc6db18a2023b9bdd698e562fc3023585a6'; //squirrel
const ValentinesPassword = '426bd089d92ad0b1a7f80e0a36ab5c4e66ccb74fa7690292797dadd0509c1f88'; //ditto
const ValentinesDayPassword = '312f7b2abbe6709aa4682f9e08a54d0dff6a9b9ff027bc4463a03377bc858771'; //frozen peas
const flowersPassword = '846d124b6785689c70bc65cf99e0ea2e896349ffeb38cf1b1224b88b3982fe94' //flowers\
const gamePassword = '6ca5cab77e702c787b4c14b3d3bf26bad43da606be6eed04ab0b9720120ae081' //game

interface PageConfigEntry {
    password: string;
    component: () => JSX.Element;
    path: string;
    name: string;
}


const pageConfig: PageConfigEntry[] = [
    {
        password: ForAudreyPassword,
        component: ForAudrey,
        path: `/${ForAudreyPassword}`,
        name: 'ForAudrey'
    },
    {
        password: SquirrelPassword,
        component: Squirrel,
        path: `/${SquirrelPassword}`,
        name: 'Squirrel'
    },
    {
        password: ValentinesPassword,
        component: Valentines,
        path: `/${ValentinesPassword}`,
        name: 'Valentines'
    },
    {
        password: ValentinesDayPassword,
        component: ValentinesDay,
        path: `/${ValentinesDayPassword}`,
        name: 'ValentinesDay'
    },
    {
        password: flowersPassword,
        component: Flowers,
        path: `/${flowersPassword}`,
        name: 'flowers'
    },
    {
        password: gamePassword,
        component: Game,
        path: `/${gamePassword}`,
        name: 'game'
    }
]

export default pageConfig;