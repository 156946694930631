import React, { ElementRef, useContext, useEffect, useRef, useState } from "react";
import { Stage, Sprite, Container, useApp, useTick } from "@pixi/react";
import squirrelImageRight from "../../../assets/squirrel.png";
import squirrelImageLeft from "../../../assets/squirrel-left.png";
import { Keyboard } from "../Key";
import { PowerupContext } from "../providers/PowerupContext";
import { InitiatedPowerupModel } from "../Powerup.model";

interface SquirrelProps {
    squirrelRef: React.RefObject<ElementRef<typeof Sprite>>
    score: number
    powerups: InitiatedPowerupModel[]
    removePowerup: (powerup: InitiatedPowerupModel) => void
}

const Squirrel = (props: SquirrelProps) => {

  const [position, setPosition] = useState({ x: 400, y: 270 });
  const [size, setSize] = useState(0.2);
  const [velocity, setVelocity] = useState(5);

  const direction = useRef({ x: 0, y: 0 });
  const app = useApp();
  const lastDirection = useRef(1); // 1 for right, -1 for left

  const minSize = 0.2;
  const maxSize = 0.6;

  const minVelocity = 5;
  const maxVelocity = 30;

  useEffect(() => {
    const upKey = Keyboard("ArrowUp");
    const downKey = Keyboard("ArrowDown");
    const leftKey = Keyboard("ArrowLeft");
    const rightKey = Keyboard("ArrowRight");

    upKey.press = () => {
      direction.current.y = -1;
    };
    upKey.release = () => {
      if (!downKey.isDown) {
        direction.current.y = 0;
      }
      else {
        direction.current.y = 1;
      }
    };

    downKey.press = () => {
      direction.current.y = 1;
    };
    downKey.release = () => {
      if (!upKey.isDown) {
        direction.current.y = 0;
      }
      else {
        direction.current.y = -1;
      }
    };

    leftKey.press = () => {
      direction.current.x = -1;
    };

    leftKey.release = () => {
      if (!rightKey.isDown) {
        direction.current.x = 0;
      }
      else {
        direction.current.x = 1;
      }
    };

    rightKey.press = () => {
      direction.current.x = 1;
    };

    rightKey.release = () => {
      if (!leftKey.isDown) {
        direction.current.x = 0;
      }
      else {
        direction.current.x = -1;
      }
    };

    return () => {
      upKey.destroy && upKey.destroy();
      downKey.destroy && downKey.destroy();
      leftKey.destroy && leftKey.destroy();
      rightKey.destroy && rightKey.destroy();
    };

  }, []); // Add velocity to dependency array

  useEffect(() => {

    props.powerups.forEach((powerup) => {
      if(powerup.timer) {
        return;
      }

      console.log('Adding powerup', powerup);

      // Add the ability of the powerup
      if(powerup.type === 'speed') {
        powerup.previousValue = velocity;
        setVelocity(Math.min(maxVelocity, velocity * 1.5));

        // Remove the ability of the powerup
        powerup.timer = setTimeout(() => {
          setVelocity(Math.max(minVelocity, velocity / 1.5));
          powerup.timer = undefined;
          props.removePowerup(powerup);
        }, powerup.duration);
      }

      if(powerup.type === 'size') {
        powerup.previousValue = size;
        setSize(Math.min(maxSize, size * 2));

        powerup.timer = setTimeout(() => {
          setSize(Math.max(minSize, size / 2));
          powerup.timer = undefined;
          props.removePowerup(powerup);
        }, powerup.duration);
      }
    })

  }, [props.powerups]);


  const gameLoop = () => {
    setPosition((prev) => {
      let newX = prev.x + direction.current.x * velocity;
      let newY = prev.y + direction.current.y * velocity;
  
      const width = app.screen?.width || 0;
      const height = app.screen?.height || 0;

        const spriteWidth = props.squirrelRef?.current?.width || 0;
        const spriteHeight = props.squirrelRef?.current?.height || 0;
  
      // Adjust the boundary conditions to account for the padding
      if (newX < spriteWidth / 2) {
        newX = spriteWidth / 2;
      } else if (newX > width - spriteWidth / 2) {
        newX = width - spriteWidth / 2;
      }
  
      if (newY < spriteHeight / 2) {
        newY = spriteHeight / 2;
      } else if (newY > height - spriteHeight / 2) {
        newY = height - spriteHeight / 2;
      }

      // console.log('Position', newX, newY)
      // console.log('Velocity', velocity)
      // console.log('Direction', direction.current)
  
      return { x: newX, y: newY };
    });
    
    // setSize((prev) => {
    //   return Math.min(maxSize, prev * (1 + sizeInterval * props.score));
    // })

    // setVelocity((prev) => {
    //   return Math.max(minVelocity, prev * (1 - sizeInterval * props.score));
    // });
  };

  useTick(gameLoop);

  const getSquirrelImage = () => {
    if (direction.current.x > 0) {
      lastDirection.current = 1; // squirrel is moving right
      return squirrelImageRight;
    } else if (direction.current.x < 0) {
      lastDirection.current = -1; // squirrel is moving left
      return squirrelImageLeft;
    } else {
      // squirrel is not moving, return the image based on the last non-zero direction
      return lastDirection.current > 0 ? squirrelImageRight : squirrelImageLeft;
    }
  };


  return (
    <Container>
      <Sprite
        ref={props.squirrelRef}
        image={getSquirrelImage()}
        x={position.x}
        y={position.y}
        anchor={{ x: 0.5, y: 0.5 }}
        scale={{ x: size, y: size }}
      />
    </Container>
  );
};

export default Squirrel;
