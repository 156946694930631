import { useEffect, useState } from "react";
import { HighScoreModel } from "../../../firebase/HighscoreModel";

interface LeaderboardProps {
    entrys: HighScoreModel[]
}

const Leaderboard = (props: LeaderboardProps) => {

    return (
        <div className="flex flex-col items-center justify-center">
            <h1 className="text-4xl font-bold mb-4">Leaderboard</h1>
            <div className="flex flex-col w-full max-w-md px-4 py-3 mx-auto bg-white rounded-lg shadow-md dark:bg-gray-800">
                {props.entrys.map((entry, index) => (
                    <div key={index} className="flex items-center mt-2 text-gray-700 dark:text-gray-200">
                        <span className="mx-2 font-bold">{index + 1}.</span>
                        <span className="w-1/2">{entry.name}</span>
                        <span className="mx-2">{entry.score}</span>
                        <span className="mx-2 text-sm text-gray-500">
                            ({entry.date.toDate().toLocaleDateString()})
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Leaderboard;