import React, { useState, createRef } from 'react';
import dictionary from '../assets/wordle_dictionary.json';

interface WordleProps {
    word: string;
    title: string;
    onWin?: () => void;
    onLose?: () => void;
}

const Wordle = (props: WordleProps) => {
  const [guesses, setGuesses] = useState(Array(5).fill(Array(props.word.length).fill('')));
  const [errorMessage, setErrorMessage] = useState('');
  const [colors, setColors] = useState(Array(5).fill(Array(props.word.length).fill('')));
  const [currentGuess, setCurrentGuess] = useState(0);
  const refs = Array(5).fill(0).map(() => Array(props.word.length).fill(0).map(() => createRef<HTMLInputElement>()));
    
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    let newGuesses = [...guesses];
    newGuesses[currentGuess][index] = e.target.value.toLowerCase();
    setGuesses(newGuesses);
    if (index < props.word.length - 1 && e.target.value) {
      refs[currentGuess][index + 1].current?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace') {
      let newGuesses = [...guesses];
      if (index > 0 && !guesses[currentGuess][index]) {
        newGuesses[currentGuess][index - 1] = '';
        refs[currentGuess][index - 1].current?.focus();
      } else {
        newGuesses[currentGuess][index] = '';
      }
      setGuesses(newGuesses);
    }
    if(e.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const currentGuessWord = guesses[currentGuess].join('');
    if (!verifyGuess(currentGuessWord)) {
        return;
    }

    // Set colors
    let newColors = [...colors];
    newColors[currentGuess] = guesses[currentGuess].map((g: string, index: number) => getColor(g, index));
    setColors(newColors);

    // Check for win
    if (guesses[currentGuess].join('') === props.word) {
      if (props.onWin) {
        props.onWin();
      }
      return;
    }
    // Check for lose
    if (currentGuess === 4) {
      if (props.onLose) {
        props.onLose();
      }
      return;
    }

    // Move to next guess
    let nextGuess = currentGuess + 1;
    setCurrentGuess(nextGuess);
    if (nextGuess < 5) {
      refs[nextGuess][0].current?.focus();
    }
  };


  const verifyGuess = (guess: string) => {
    if (guess.length !== 5) {
      setErrorMessage('come on you need 5 letters!');
      return false;
    }
    if (guess.match(/[^a-zA-Z]/)) {
      setErrorMessage('only letters boo');
      return false;
    }

    // check if word is in dictionary
    if (!dictionary.includes(guess)) {
      setErrorMessage('not a valid word!');
      return false;
    }

    // if we are here make sure to clear the error message
    setErrorMessage('');
    
    return true;
  }

  const getColor = (guess: string, index: number) => {
    if (guess === props.word[index]) {
      return 'bg-green-200';
    } else if (props.word.includes(guess)) {
      return 'bg-yellow-200';
    } else {
      return 'bg-red-200';
    }
  };

  return (
    <div className="flex flex-col items-center justify-center gap-5">
      <h1 className="text-4xl mb-4">{props.title}</h1>
        <div className="flex flex-col items-center justify-center">
            {guesses.map((guess, guessIndex) => (
                <div key={guessIndex} className="grid grid-cols-5 gap-10 mb-4">
                    {guess.map((g: string, index: number) => (
                        <input
                            key={index}
                            type="text"
                            maxLength={1}
                            className={`w-10 h-10 text-center text-2xl border-solid border-2 border-black-500 ${colors[guessIndex][index]}`}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            disabled={guessIndex !== currentGuess}
                            ref={refs[guessIndex][index]}
                        />
                    ))}
                </div>
            ))}
        </div>
      <div>
        {/* Error message */}
        {errorMessage && errorMessage.length > 0 && <p className="text-red-500">{errorMessage}</p>}
      </div>
      <button className="px-4 py-2 bg-sky-400 text-white rounded-lg" onClick={handleSubmit}>Submit</button>
    </div>
  );
};

export default Wordle;